<template>
  <EnvBadge />
  <PageLoader />
  <Component
    :is="layout"
    v-if="layout"
    :set-page-title="setPageTitle"
  />
  <router-view
    v-if="!layout"
    class="app-body"
  />
</template>

<script setup>
import EnvBadge from '@shared/components/EnvBadge.vue'
import PageLoader from '@shared/components/Pageloader.vue'
import B2BLayout from '@us/app/containers/B2BLayout.vue'
import B2BLandingLayout from '@us/app/containers/B2BLandingLayout.vue'
import B2BLandingTestLayout from '@us/app/containers/B2BLandingPageABTest.vue'
import B2BSimpleLandingLayout from '@us/app/containers/B2BSimpleLandingLayout.vue'
import { changeLocale } from '@shared/localization'
import { getLangCodeByLanguage } from '@shared/utils'
import Tracker from '@shared/Tracker'
import { useI18n } from 'petite-vue-i18n'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useStore()
const route = useRoute()

const layoutMap = {
  B2BLayout,
  B2BLandingLayout,
  B2BLandingTestLayout,
  B2BSimpleLandingLayout,
}

const layout = computed(() => layoutMap[route.meta.layout])
const lang = computed(() => store.getters.lang)
const user = computed(() => store.getters.user)

// update the i18n language whenever the language in the store changes
watch(
  lang,
  (lang) => {
    changeLocale(getLangCodeByLanguage(lang), document.documentElement)
    // reload translations for the corporate
    if (store.getters.corporate) {
      store.dispatch('fetchCorporate', {
        name: store.getters.corporate.key,
      })
    }
  },
  { immediate: true },
)

watch(user, (user) => Tracker.setUser(user), {
  immediate: true,
})

const setPageTitle = (newTitle) => {
  if (newTitle && typeof newTitle === 'string') {
    document.querySelector('title').textContent = newTitle
  }
}

setPageTitle(t('b2b__landing_page__generic__page_title'))
</script>

<style lang="scss">
/*@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap');*/
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Light.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Light.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Light.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Regular.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Regular.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Medium.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Medium.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Medium.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-SemiBold.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-SemiBold.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Bold.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Bold.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Bold.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@import '@shared/assets/scss/style';

.app {
  &-header {
    display: none;
  }

  &-body {
    margin: 0 auto;
    background-color: $white;
  }

  @media (min-width: $size-medium) {
    .app-download-link-layout {
      padding-top: 40px;
      background-color: $white-5;
      min-height: calc(100% - 120px);
    }

    &-header {
      display: block;
      height: 80px;
      background-color: $white;
      border-bottom: 1px solid $gray-5;

      &::before {
        content: '';
        display: block;
        height: 100%;
        max-width: 784px;
        margin: 0 auto;
        background: url('@shared/assets/img/kaia_health_logo.svg') left center
          no-repeat;
        background-size: 120px 100px;
      }
    }
  }
}
</style>
