import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {
  getDefaultLanguage,
  getLangCodeByLanguage,
  getSupportedLangCodes,
  normalizeLang,
} from '@shared/utils'
import { sessionStorage } from '@shared/helpers/storage'
import assessmentModuleStore from '@shared/modules/assessment/state'
import { assignLocaleMessage, changeLocale } from '@shared/localization.js'
import User from '@shared/data/models/user.js'
import * as apiRequest from '@shared/api-endpoints/index.js'
import groupVerificationConfig from '@us/app/config/b2b-group-verification.json'
import getUrlTrackingRecord from '@us/app/helpers/getUrlTrackingRecord.js'
import Corporate from '@shared/data/models/corporate.js'

const applyUserLanguage = ({ commit }, userLanguage) => {
  const supportedLangCodes = getSupportedLangCodes()
  let langCode = getLangCodeByLanguage(userLanguage)
  if (!supportedLangCodes.includes(langCode)) {
    langCode = supportedLangCodes[0]
    userLanguage = normalizeLang(langCode)
  }
  changeLocale(langCode, document.documentElement)
  commit('lang', userLanguage)
}

const storeSession = async ({ commit, dispatch }, response) => {
  const userData = response.data?.user
  const user = userData && new User(userData) // main entrypoint for the user into the store

  commit('user', user)
  applyUserLanguage({ commit }, user?.profile?.language)

  const corporateKey = user?.currentSubscription?.corporateKey

  if (corporateKey) {
    await dispatch('ensureCorporate', { name: corporateKey })
  }

  return user
}

const login = ({ dispatch }, { email, password, stayLoggedIn }) =>
  apiRequest
    .login(email, password, stayLoggedIn)
    .then((data) => dispatch('storeSession', data))

const getUser = ({ dispatch }) =>
  apiRequest.getUser().then((data) => dispatch('storeSession', data))

const setUserEmail = (_, email) => apiRequest.setUserEmail(email)

const setUserProfile = ({ dispatch }, profile) =>
  apiRequest
    .setUserProfile(profile)
    .then((data) => dispatch('storeSession', data))

const signUp = ({ dispatch }, payload) => {
  return apiRequest
    .signUp(payload)
    .then((data) => dispatch('storeSession', data))
}

const resetPassword = (_, { email }) => apiRequest.resetPassword({ email })

const setLang = ({ commit }, lang) => {
  commit('lang', lang)
}

const loginWithOneTimeAuthToken = ({ dispatch }, { token }) =>
  apiRequest
    .loginWithOneTimeAuthToken(token)
    .then((data) => dispatch('storeSession', data))

const eligibilitySessionHashKey = 'ELIGIBILITY_ATTEMPT_HASH'

const storeVerificationParams = ({ commit, getters }, params) => {
  commit('verificationParams', { ...getters.verificationParams, ...params })
  if (params.eligibility_session_hash !== undefined) {
    sessionStorage.setItem(
      eligibilitySessionHashKey,
      params.eligibility_session_hash,
    )
  }
}

/**
 * returns the eligibility session hash and sets it if it is not set yet
 * @param {string} corporateKey - the corporate key to encode in the hash
 * @returns {string} the eligibility session hash
 */
const getOrSetEligibilitySessionHash = (corporateKey) => {
  let hash = getEligibilitySessionHash()
  if (hash === null) {
    hash = btoa(`${corporateKey}${Date.now()}`)
    sessionStorage.setItem(eligibilitySessionHashKey, hash)
  }
  return hash
}

/**
 * @returns {string | null} the eligibility session hash or null if it is not set
 */
export const getEligibilitySessionHash = () =>
  sessionStorage.getItem(eligibilitySessionHashKey)

const checkVoucher = ({ commit }, code) =>
  apiRequest
    .checkVoucherB2b(code)
    .then(({ data }) => commit('voucher', data.corporate_voucher))

const getCorporateEligibilityConfig = (_, { corporateKey, lang }) => {
  if (corporateKey in groupVerificationConfig) {
    return apiRequest
      .getCorporateEligibilityGroupConfig(
        groupVerificationConfig[corporateKey].groupId,
        lang,
      )
      .then((res) => res.data.corporate_eligibility_group_config)
  }

  return apiRequest
    .getCorporateEligibilityConfig(corporateKey, lang)
    .then((res) => res.data.corporate_eligibility_config)
}

/**
 * Internal function
 * Takes the verification data from the user input and check it against the
 * eligibility list of one corporate (defined by the corporate_key in the
 * corporateEligibilityData)
 */
const checkCorporateOrGroupEligibility = (
  { commit },
  corporateEligibilityData,
) => {
  const groupConfig =
    groupVerificationConfig[corporateEligibilityData.corporate_key]
  if (groupConfig !== undefined) {
    corporateEligibilityData.corporate_eligibility_group_id =
      groupConfig.groupId
    return apiRequest
      .checkCorporateGroupEligibility(corporateEligibilityData)
      .then((res) =>
        // TODO confirm that we actually want to redirect to the corporate that is associated to the eligibility list entry
        commit('corporateEligibilityData', {
          ...corporateEligibilityData,
          corporate_key: res.data.corporate_key,
        }),
      )
  }

  return apiRequest
    .checkCorporateEligibility({
      ...corporateEligibilityData,
    })
    .then(() => commit('corporateEligibilityData', corporateEligibilityData))
    .catch((e) => {
      commit('corporateEligibilityData', {})
      throw e
    })
}

/**
 * Internal function
 * Takes verification data from the user input and checks it against the
 * eligibility lists of the corporates and groups listed in the list param
 */
const checkEligibilityForListOfCorporatesAndGroups = (
  { commit },
  corporateEligibilityData,
  list,
) => {
  return checkCorporateOrGroupEligibility(
    { commit },
    {
      ...corporateEligibilityData,
      // inject the corporate of the current list to check into the payload
      corporate_key: list[0],
    },
  ).catch((e) => {
    // if there are other lists to check and no entry in the current list was a
    // match check the next list (recursion) otherwise return the original error
    if (list.length > 1 && e?.response?.data?.errors?.[0] === 'NOT_ELIGIBLE') {
      commit('corporateEligibilityData', {})
      return checkEligibilityForListOfCorporatesAndGroups(
        { commit },
        corporateEligibilityData,
        list.slice(1),
      )
    } else {
      throw e
    }
  })
}

/**
 * The only eligibility check function that should be called from the outside
 * Takes verification data from the user input and checks against all relevant
 * eligibility lists (in sequence)
 * Which lists to check is defined in the groupVerificationConfig
 */
const checkCorporateEligibility = ({ commit }, corporateEligibilityData) => {
  // ensure that all checks will have the same eligibility session hash
  corporateEligibilityData.eligibility_session_hash =
    getOrSetEligibilitySessionHash(corporateEligibilityData.corporate_key)

  const groupConfig =
    groupVerificationConfig[corporateEligibilityData.corporate_key]

  // identify which eligibility lists to check (or rather the lists of which corporates or groups to check)
  const listsToCheck = [
    corporateEligibilityData.corporate_key,
    ...(groupConfig?.otherEligibilityListsToCheck ?? []),
  ]

  return checkEligibilityForListOfCorporatesAndGroups(
    { commit },
    corporateEligibilityData,
    listsToCheck,
  )
}

const checkEligibilityWithToken = (_, { corporate_key, eligible_token }) =>
  apiRequest.checkEligibilityWithEligibleToken(
    getOrSetEligibilitySessionHash(corporate_key),
    eligible_token,
  )

const checkEmail = ({ commit }, { email, corporate }) =>
  apiRequest.checkEmailB2b(email, corporate).then(({ data }) =>
    commit('voucher', {
      emailCode: email,
      corporate_id: data.id,
      corporate_name: data.key,
    }),
  )

const assignTranslations = ({ corporate, lang }) => {
  assignLocaleMessage(corporate.translations, getLangCodeByLanguage(lang))
  return corporate
}

const corporateToStore =
  ({ commit }) =>
  (corporate) => {
    commit('corporate', corporate)
    return corporate
  }

const utmsToStore = ({ commit }, queryString) => {
  const utmData = {
    referrer: document.referrer,
  }
  const trackingRecord = getUrlTrackingRecord(queryString)
  for (let i in trackingRecord.record) {
    utmData[i] = trackingRecord.record[i]
  }

  commit('utmData', utmData)
}

const updateCorporate = (context, lang, corporate) =>
  Promise.resolve(assignTranslations({ lang, corporate })).then(
    corporateToStore(context),
  )

const fetchCorporate = (context, { name, lang, isLangSelected = false }) => {
  const selectedLang = (isLangSelected && lang) || context.getters.lang
  return apiRequest
    .getCorporate(name, selectedLang)
    .then(({ data }) => new Corporate(data))
    .then((corporateInstance) =>
      updateCorporate(context, selectedLang, corporateInstance),
    )
}

const ensureCorporate = (context, { name }) => {
  const { corporate, lang } = context.getters
  return corporate?.key === name
    ? updateCorporate(context, lang, corporate)
    : fetchCorporate(context, { name, lang })
}

const subscribeUserWithVoucher = ({ dispatch }, payload) =>
  apiRequest
    .subscribeB2BUserWithVoucher(payload)
    .then(() => dispatch('getUser'))

const subscribeUserWithEligibleToken = (
  { dispatch },
  { corporate_key, eligible_token },
) =>
  apiRequest
    .subscribeWithEligibleToken(
      getOrSetEligibilitySessionHash(corporate_key),
      eligible_token,
    )
    .then(() => dispatch('getUser'))

const subscribeUserWithVerificationData = ({ dispatch }, verificationData) =>
  apiRequest
    .subscribeWithVerificationData(verificationData)
    .then(() => dispatch('getUser'))

export default new Vuex.Store({
  strict: import.meta.env.NODE_ENV !== 'production',
  state: {
    /** @type {User} */
    user: null,
    country: 'US',
    lang: getDefaultLanguage(),
    checkInStatus: '',
    voucher: null,
    /** @type {Corporate} */
    corporate: null,
    // TODO rename to initialQuery (or something similar).
    // This should not be used to blindly fill any data that is send to the backend
    verificationParams: {},
    corporateEligibilityData: {},
    redirectLink: {},
    utmData: {
      source: null,
      medium: null,
      campaign: null,
      content: null,
      term: null,
      referrer: null,
    },
    formData: {
      acceptData: false,
      acceptPhoneCall: false,
      acceptTerms: false,
      acceptTracking: false,
      confirmed: false,
      dateOfBirth: '',
      email: '',
      emailCode: '',
      lastName: '',
      firstName: '',
      password: '',
      phoneNumber: '',
      stayLoggedIn: false,
      voucherCode: '',
    },
    signupContext: {
      webEntryPointUrl: '',
      webEntryPointReferrerUrl: '',
    },
  },
  getters: {
    user: (state) => state.user,
    country: (state) => state.country,
    lang: (state) => state.lang,
    checkInStatus: (state) => state.checkInStatus,
    voucher: (state) => state.voucher,
    corporate: (state) => state.corporate,
    utmData: (state) => state.utmData,
    verificationParams: (state) => state.verificationParams,
    corporateEligibilityData: (state) => state.corporateEligibilityData,
    formData: (state) => state.formData,
    phoneNumber: (state) => state.formData.phoneNumber,
    signupContext: (state) => state.signupContext,
    redirectLink: (state) => state.redirectLink,
  },
  mutations: {
    user(state, value) {
      state.user = value
    },
    lang(state, value) {
      state.lang = value
    },
    checkInStatus(state, value) {
      state.checkInStatus = value
    },
    voucher(state, value) {
      state.voucher = value
    },
    corporate(state, value) {
      state.corporate = value
    },
    verificationParams(state, value) {
      state.verificationParams = value
    },
    corporateEligibilityData(state, value) {
      state.corporateEligibilityData = value
    },
    formData(state, value) {
      state.formData = { ...state.formData, ...value }
    },
    utmData(state, value) {
      state.utmData = value
    },
    signupContext(state, { webEntryPointUrl, webEntryPointReferrerUrl }) {
      // only update the fields in the signup context if they were not set yet
      state.signupContext.webEntryPointUrl ||= webEntryPointUrl
      state.signupContext.webEntryPointReferrerUrl ||= webEntryPointReferrerUrl
    },
    redirectLink(state, value) {
      state.redirectLink = value
    },
  },
  actions: {
    login,
    getUser,
    setUserEmail,
    setUserProfile,
    signUp,
    resetPassword,
    setLang,
    storeSession,
    loginWithOneTimeAuthToken,
    checkVoucher,
    fetchCorporate,
    ensureCorporate,
    subscribeUserWithVoucher,
    subscribeUserWithEligibleToken,
    subscribeUserWithVerificationData,
    checkEmail,
    getCorporateEligibilityConfig,
    checkCorporateEligibility,
    checkEligibilityWithToken,
    storeVerificationParams,
    utmsToStore,
  },
  modules: {
    assessment: assessmentModuleStore,
  },
  plugins: [
    new VuexPersistence({
      storage: sessionStorage,
    }).plugin,
  ],
})
